<div class="med-form-comp" [formGroup]="form">
  <div (keydown)="($event.keyCode == 13 ? false : true)">
    <div
      *ngIf="
        config?.protocol.present &&
        formType == 'new' &&
        (form.value?.brandName != undefined || form.value?.brandName != null)
      "
      class="order-brandName"
      [matTooltip]="
        form.value.brandName?.length > 50 ? form.value.brandName : ''
      "
      matTooltipClass="cp-mat-tooltip"
    >
      {{ form.value.brandName | titlecase }}
      <div><hr /></div>
    </div>
    <preset-fields
      *ngIf="config?.presetName?.present"
      [config]="config"
    ></preset-fields>
    <cp-patient-type *ngIf="config?.patientType?.present"></cp-patient-type>
    <div class="add-med-cont">
      <div class="" *ngIf="config.name.display">
        <label>Name*</label>
        <input
          matInput
          formControlName="name"
          type="text"
          class="order-input"
        />
        <span
          class="error-text"
          *ngIf="
            utilService.hasError(form, 'name', 'required') &&
            (name.touched || name.dirty)
          "
        >
          Name is required
        </span>
        <span
          class="error-text"
          *ngIf="
            utilService.hasError(form, 'name', 'maxlength') &&
            (name.touched || name.dirty)
          "
        >
          Max 100 characters
        </span>
      </div>

      <div class="homemed-bedside-cont">
        <ng-container
          *ngIf="config.pta.present && formType === 'new' && !orderInHospital"
        >
          <button
            mat-flat-button
            tabindex="-1"
            type="button"
            onclick="this.blur()"
            (click)="onHomeMedChange()"
            [ngClass]="
              form.get('pta').value ? 'button-primary' : 'button-secondary'
            "
          >
            Home meds
          </button>
        </ng-container>

        <ng-container
          *ngIf="
            config.bedsideOrder.present &&
            formType === 'new' &&
            !orderInHospital &&
            showBedsideForm
          "
        >
          <button
            mat-flat-button
            tabindex="-1"
            type="button"
            onclick="this.blur()"
            (click)="onBedsideChange()"
            [ngClass]="
              form.get('bedsideOrder').value
                ? 'button-primary'
                : 'button-secondary'
            "
          >
            Bedside Order
          </button>
        </ng-container>
      </div>

      <div
        class="add-med"
        [class.mt-3]="config.presetName.present"
        [class.form-group]="!config.presetName.present"
        *ngIf="config.comb.present"
      >
        <button
          mat-flat-button
          class="button-primary"
          (click)="addCombination($event)"
          [disabled]="totalCombination >= maxCombination"
        >
          Add medicine
        </button>
      </div>
    </div>

    <div class="range">
      <mat-checkbox
        formControlName="doseRange"
        (change)="doseRangeChange($event.checked)"
      >
        Range
      </mat-checkbox>
    </div>
    <div class="orderable-title">
      <span
        class="orderable-text"
        [matTooltip]="orderableName?.length > 50 ? orderableName : ''"
        matTooltipClass="cp-mat-tooltip"
        >{{ orderableName || form.value.name }}</span
      >
    </div>
    <div>
      <div class="dose-range-cont">
        <div class="dose-rate">
          <label class="order-label">
            Dose/Rate*
            <br />
            <span
              class="med-name"
              *ngIf="getFirstCombName(orderableName) as medName"
              >( {{ medName }} )</span
            >
          </label>
          <input
            matInput
            (wheel)="$event.preventDefault()"
            formControlName="quantity"
            type="number"
            class="order-input"
          />
          <span
            class="error-text"
            *ngIf="
              utilService.hasError(form, 'quantity', 'required') &&
              (quantity.touched || quantity.dirty)
            "
          >
            Dose is required
          </span>
          <span
            class="error-text"
            *ngIf="
              utilService.hasError(form, 'quantity', 'negativeError') &&
              (quantity.touched || quantity.dirty)
            "
          >
            Rate of order should be greater than 0
          </span>
        </div>
        <div
          [ngClass]="{ unit: getFirstCombName(orderableName) }"
          *ngIf="doseRange.value"
        >
          <label class="order-label">Max*</label>
          <input
            matInput
            (wheel)="$event.preventDefault()"
            formControlName="maxDose"
            type="number"
            class="order-input"
          />
          <span
            class="error-text"
            *ngIf="
              utilService.hasError(form, 'maxDose', 'required') &&
              (maxDose.touched || maxDose.dirty)
            "
          >
            Max dose is required
          </span>
          <span
            class="error-text"
            *ngIf="form.errors?.range && (form.touched || form.dirty)"
          >
            Less than dose
          </span>
        </div>
        <div [ngClass]="{ unit: getFirstCombName(orderableName) }">
          <label class="order-label">Units*</label>
          <cp-searchable-dropdown
            formControlName="unit"
            searchPlaceholder="Search for units"
            placeholder="Select a unit"
            panelClass="cp-order-dropdown"
            inputClass="order-select"
            [data]="units"
          >
          </cp-searchable-dropdown>
          <ng-container *ngTemplateOutlet="orderError"></ng-container>
          <span
            class="error"
            *ngIf="
              utilService.hasError(form, 'unit', 'required') &&
              (unit.touched || unit.dirty)
            "
          >
            Unit is required
          </span>
        </div>
      </div>

      <div
        class="conc-unit-cont"
        *ngIf="
          fType &&
          fType.value === 'continuous' &&
          unit &&
          isConcentrationUnit(unit.value)
        "
      >
        <div class="concentration-cont">
          <div
            class="conc-form"
            [ngStyle]="{ width: doseRange.value ? '66%' : '100%' }"
            formGroupName="concentration"
          >
            <div class="conc">
              <label class="order-label">Conc. *</label>
              <input
                matInput
                (wheel)="$event.preventDefault()"
                type="number"
                class="order-input"
                formControlName="value"
              />
            </div>
            <div class="conc-unit">
              <label class="order-label">Conc. Unit *</label>
              <cp-searchable-dropdown
                formControlName="unit"
                searchPlaceholder="Search for units"
                placeholder="Select a unit"
                inputClass="order-select"
                panelClass="cp-order-dropdown"
                [data]="concentrationUnits"
              >
              </cp-searchable-dropdown>
            </div>
          </div>
        </div>

        <div
          class="conc-errors"
          *ngIf="
            form?.errors?.invalidConcentration &&
            (concentrationValue?.touched || concentrationUnit?.touched)
          "
        >
          <div class="error-text">
            {{ form?.errors?.invalidConcentration?.message }}
          </div>
        </div>

        <div
          class="next-dose-cont"
          *ngIf="medSchedule && medSchedule.length > 0"
        >
          <div>Next dose at:</div>
          <div *ngFor="let schedule of medSchedule">
            <span>{{ schedule | date : "d/M/yy, HH:mm" }}</span>
          </div>
        </div>
      </div>
      <div class="route-form-cont">
        <div class="route">
          <label class="order-label">Route*</label>
          <cp-searchable-dropdown
            formControlName="route"
            placeholder="Select a route"
            inputClass="order-select"
            panelClass="cp-order-dropdown"
            searchPlaceholder="Search for routes"
            [data]="routes"
          >
          </cp-searchable-dropdown>
          <ng-container *ngTemplateOutlet="orderError"></ng-container>
          <span
            class="error-text"
            *ngIf="
              utilService.hasError(form, 'route', 'required') &&
              (route.touched || route.dirty)
            "
          >
            Route is required
          </span>
        </div>
        <div class="form">
          <label class="order-label">Form</label>
          <input
            matInput
            formControlName="form"
            type="text"
            class="order-input"
          />
        </div>
      </div>
      <div
        style="display: flex; gap: 1rem; width: 100%"
        *ngIf="
          config.bodyWeight.present &&
          form.get('unit')?.value?.split('/')?.includes('kg')
        "
      >
        <div class="wt body-weight" style="width: 50%">
          <label class="order-label">Weight*</label>
          <input
            matInput
            [ngClass]="{
              'order-input--warning':
                bodyWeight?.value &&
                currentWeight &&
                bodyWeight?.value >= currentWeight * 2
                  ? true
                  : false
            }"
            (wheel)="$event.preventDefault()"
            type="number"
            class="order-input"
            formControlName="bodyWeight"
          />
          <span
            class="body-weight--warning cp-pt-1 text-orange-400"
            *ngIf="
              bodyWeight?.value &&
              currentWeight &&
              bodyWeight?.value >= currentWeight * 2
            "
          >
            <mat-icon>info</mat-icon>
            Exceeds double the current weight.
          </span>
        </div>
        <div
          class="wt"
          style="width: 50%"
          *ngIf="currentPatient?.patientType !== patientType?.Adult"
        >
          <label class="order-label">Total dose</label>
          <input
            matInput
            (wheel)="$event.preventDefault()"
            type="text"
            class="order-input cp-d"
            formControlName="totalDose"
            [readonly]="true"
          />
        </div>
      </div>
      <div class="display-flex">
        <div
          class="conc-rate-cont"
          style="padding-top: 2rem; min-height: 4rem; left: 23rem; width: 50%"
          *ngIf="concentrationRate?.rate?.min"
        >
          <span>{{ concentrationRate.rate.min }} </span>
          <span *ngIf="concentrationRate.rate?.max">
            - {{ concentrationRate.rate.max }}</span
          >
          <span>(ml/hr)</span>
        </div>
      </div>
      <div class="conc-errors" *ngIf="form?.errors?.invalidWeight">
        <div class="error-text">
          {{ form?.errors?.invalidWeight?.message }}
        </div>
      </div>
      <hr *ngIf="combination.controls.length > 0" />
    </div>
    <div class="med-comb-cont" formArrayName="combination">
      <ng-container *ngFor="let comb of combination.controls; let i = index">
        <ng-container [formGroupName]="i">
          <hr *ngIf="i > 0 && clickedItem[i] != comb.value.name" />
          <div class="med-comb" *ngIf="clickedItem[i] != comb.value.name">
            <div class="name">
              <mat-label class="order-label">Name*</mat-label>
              <input
                type="text"
                class="order-input"
                matInput
                formControlName="name"
                [container]="'med-form-' + i"
                [matAutocomplete]="auto"
              />

              <mat-autocomplete
                (optionSelected)="selectedItem($event.option.value, i)"
                #auto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                  [matTooltip]="option?.length > 31 ? option : ''"
                  matTooltipClass="cp-mat-tooltip"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>

              <span
                class="error-text"
                *ngIf="
                  hasCombError('name', 'required', i) &&
                  (getCombItemField('name', i).touched ||
                    getCombItemField('name', i).dirty)
                "
              >
                Name is required
              </span>
              <span
                class="error-text"
                *ngIf="
                  hasCombError('name', 'maxlength', i) &&
                  (getCombItemField('name', i).touched ||
                    getCombItemField('name', i).dirty)
                "
              >
                Max 200 characters
              </span>
            </div>
            <div class="delete-icon cp-cursor-pointer">
              <mat-icon
                class="delete-icon-color"
                (click)="removeCombination(i)"
                matTooltip="Delete"
                >delete</mat-icon
              >
            </div>
          </div>
          <div
            class="orderable-title mt"
            *ngIf="clickedItem[i] == comb.value.name"
          >
            <div
              class="orderable-text"
              [matTooltip]="
                comb.value?.name?.length > 50 ? comb?.value?.name : ''
              "
              matTooltipClass="cp-mat-tooltip"
            >
              {{ comb.value.name }}
            </div>
            <div class="delete-icon cp-cursor-pointer display-flex">
              <mat-icon
                class="delete-icon-color"
                (click)="removeCombination(i)"
                matTooltip="Delete"
                >delete</mat-icon
              >
            </div>
          </div>
          <div *ngIf="clickedItem[i] == comb.value.name">
            <div class="med-comb">
              <div class="dose-rate">
                <label class="order-label">Dose/Rate*</label>
                <input
                  matInput
                  (wheel)="$event.preventDefault()"
                  formControlName="quantity"
                  type="number"
                  class="order-input"
                />
                <span
                  class="error-text"
                  *ngIf="
                    hasCombError('quantity', 'required', i) &&
                    (getCombItemField('quantity', i).touched ||
                      getCombItemField('quantity', i).dirty)
                  "
                >
                  Required
                </span>
                <span
                  class="error-text"
                  *ngIf="
                    hasCombError('quantity', 'negativeError', i) &&
                    (getCombItemField('quantity', i).touched ||
                      getCombItemField('quantity', i).dirty)
                  "
                >
                  Rate of order should be greater than 0
                </span>
              </div>

              <div class="units">
                <label class="order-label">Units*</label>
                <cp-searchable-dropdown
                  formControlName="unit"
                  placeholder="Select a unit"
                  inputClass="order-select"
                  panelClass="cp-order-dropdown"
                  searchPlaceholder="Search for units"
                  [data]="subUnit | filterUnit : unit"
                >
                </cp-searchable-dropdown>
                <span
                  class="error-text"
                  *ngIf="
                    hasCombError('unit', 'required', i) &&
                    (getCombItemField('unit', i).touched ||
                      getCombItemField('unit', i).dirty)
                  "
                >
                  Unit is required
                </span>
              </div>
            </div>

            <ng-container
              *ngIf="
                fType?.value === 'continuous' &&
                isConcentrationUnit(getCombItemField('unit', i)?.value)
              "
            >
              <div class="med-comb" formGroupName="concentration">
                <div class="dose-rate">
                  <label class="order-label">Conc. *</label>
                  <input
                    matInput
                    (wheel)="$event.preventDefault()"
                    formControlName="value"
                    type="number"
                    class="order-input"
                  />
                </div>

                <div class="units">
                  <label class="order-label">Conc. Unit *</label>
                  <cp-searchable-dropdown
                    formControlName="unit"
                    searchPlaceholder="Search for units"
                    placeholder="Select a unit"
                    inputClass="order-select"
                    panelClass="cp-order-dropdown"
                    [data]="concentrationUnits"
                  >
                  </cp-searchable-dropdown>
                </div>
              </div>
              <div
                class="conc-errors"
                *ngIf="
                  comb?.errors?.invalidConcentration &&
                  getCombItemField('concentration.value', i)?.touched &&
                  getCombItemField('concentration.unit', i)?.touched
                "
              >
                <div class="error-text">
                  {{ comb?.errors?.invalidConcentration?.message }}
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <hr />
  </div>
  <div class="freq-cont">
    <app-order-frequency
      [config]="config"
      (daysChange)="onDaysChange($event)"
      (doseChange)="onDoseChange($event)"
      [unit]="unit.value"
      [formType]="formType"
      [validateFtype]="true"
    >
    </app-order-frequency>
  </div>

  <app-order-detail [value]="value"></app-order-detail>
</div>

<ng-template #orderError>
  <div *ngIf="orderDataError">
    <span class="error-text">Something went wrong</span>
  </div>
</ng-template>
